
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

interface ticketData {
  rut: string;
  name: string;
  father_last_name: string;
  mother_last_name: string;
  document_type: string;
  document_id: string;
  phone: number | null;
  email: string;
  message: string;
  patente: string;
}
@Component({
  components: {
    FilePond
  }
})
export default class SendTransitTicketForm extends Mixins(FormValidator) {
  protected formData: ticketData = {
    rut: "",
    name: "",
    father_last_name: "",
    mother_last_name: "",
    document_type: "1",
    document_id: "",
    phone: null,
    email: "",
    message: "",
    patente: ""
  };

  private chilean: boolean = true;

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  private mounted() {
    (window as any).grecaptcha.render("grecaptcha", {
      sitekey: "6LfgYMgUAAAAAEhThu7w7hH2oyuo4eKhjfsmNPua"
    });
  }

  private options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  private sendTicketAttemp() {
    //this.disable_button = true;
    if (!this.areAllFilesOK()) {
      this.$q.notify({
        message: this.$t("notification.problem_with_files") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      //this.disable_button = false;
      return;
    }

    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.$q.notify({
        message: this.$t("notification.check_captcha") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      //this.disable_button = false;
      return;
    }

    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }
    if (adjuntos_empty) {
      adjuntos = null;
    }

    // enviar post al backend.
    this.$axios
      .post(
        "tickets/solicitud/permisoCirculacion",
        {
          ticket: {
            id_servicio: 39,
            mensaje:
              "Patente Vehicular: " +
              this.formData.patente +
              "\n" +
              this.formData.message,
            origen: 4,
            tipo: 0,
            direccion: {
              id_comuna: 108,
              calle: "Alcalde Fernando Castillo Velasco",
              numero: 9954,
              aclaratoria: ""
            }
          },
          direccion: {
            id_comuna: 108,
            calle: "Alcalde Fernando Castillo Velasco",
            numero: 9954,
            aclaratoria: ""
          },
          dispositivo: navigator.userAgent,
          captcha: captcha,
          persona: {
            rut: this.formData.document_id,
            nombre: this.formData.name,
            apellido_paterno: this.formData.father_last_name,
            apellido_materno: this.formData.mother_last_name,
            email: this.formData.email,
            telefono: this.formData.phone,
            tipo_documento: 1,
            id_pais: 47
          },
          adjunto: adjuntos
        },
        {
          headers: {
            Authorization: this.$store.state.user.auth_token
              ? this.$store.state.user.auth_token
              : null
          }
        }
      )
      .then(response => {
        let ticket = {
          id: response.data.id_ticket,
          guest_id: this.formData.document_id,
          guest_name: this.formData.name,
          guest_lastname: this.formData.father_last_name,
          guest_mother_lastname: this.formData.mother_last_name,
          message: response.data.mensaje,
          date: response.data.fecha,
          service_name: "Permisos de Circulación",
          step: 4,
          adjuntos: response.data.adjunto ? response.data.adjunto : null
        };
        this.$store.dispatch("submitFinishedGuestRequest", ticket);
      })
      .catch(error => {
        return error;
      });
  }

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "PUT",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };
}
